<template>
  <MainLayout
    isFullScreen
    class="main-layout--artist-welcome">
    <img
      :src="heroImage"
      alt=""
      class="emboarding-welcome__hero">
    <router-link
      :to="{ name: 'LandingPage' }"
      class="emboarding-welcome__logo">
      <Logo
        :size="'base'"
        :color="'white'">
      </Logo>
    </router-link>
    <section class="emboarding-welcome__wrapper">
      <header :class="{ 'mb-auto': !$mediaQueries.isDesktop }">
        <Heading class="mb-xs">
          {{ $t('artist.signupSuccess.successTitle', { firstname: user.Firstname }) }}
        </Heading>
        <Paragraph v-html="
          isArtist
            ? $t('artist.signupSuccess.successMessage')
            : $t('artist.dashboard.client.signup.success.message')
        ">
        </Paragraph>
      </header>
      <router-link :to="{ name: isArtist ? 'ArtistProfile' : 'ClientDashboard' }">
        <Button :isFluid="!$mediaQueries.isDesktop">
          {{
            isArtist
              ? $t('artist.mailValidated.fillProfile')
              : $t('artist.dashboard.client.signup.success.cta')
          }}
        </Button>
      </router-link>
    </section>
  </MainLayout>
</template>

<script>

import {
  mapState,
  mapMutations,
}                           from 'vuex';

import MainLayout           from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Logo                 from '../../../components/atoms/Logo/a-Logo.vue';
import Heading              from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph            from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button               from '../../../components/atoms/Button/a-Button.vue';
import ArtistHeroImage      from '../../../../public/assets/images/artiste/welcome.jpg';
import ClientHeroImage      from '../../../../public/assets/images/client/welcome.jpg';


export default {
  name: 'o-EmboardingWelcome',
  components: {
    MainLayout,
    Logo,
    Heading,
    Paragraph,
    Button,
  },
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('User', ['user']),
    ...mapState('Routing', ['pendingRedirect']),
    heroImage() {
      return this.isArtist ? ArtistHeroImage : ClientHeroImage;
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isArtist || !this.pendingRedirect) return next();

    next(false);

    this.$router.push(this.pendingRedirect);
    this.setPendingRedirect(null);
  },
  methods: {
    ...mapMutations({
      setPendingRedirect: 'Routing/SET_PENDING_REDIRECT',
    }),
  },
}

</script>

<style lang="scss">

.emboarding-welcome {
  &__hero {
    grid-row: 1 / 2;
    min-width: 100%;
    max-height: 300px;
    object-fit: cover;

    @media screen and ($desktop) {
      position: fixed;
      left: 0;
      right: 0;
      min-width: 50%;
      max-width: 50%;
      min-height: 100%;
      max-height: 100%;
    }
  }

  &__logo {
    position: absolute;
    top: 40px;
    left: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;

    @media screen and ($desktop) {
      top: 100px;
    }
  }

  &__wrapper {
    display: grid;
    grid-column: 1 / 2;
    grid-template-rows: 1fr auto;
    flex-direction: column;
    align-items: center;
    padding: var(--space-lg);

    @media screen and ($desktop) {
      grid-column: 2 / 3;
      grid-template-rows: auto auto;
      grid-row-gap: var(--space-lg);
      max-width: 800px;
      justify-content: flex-start;
      align-content: center;
      padding: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
  }
}

</style>
